import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, FormGroup, Label, Row,} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {createAdmin, getAdmins, getUsers} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import {notification} from "antd";

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'ახალი ადმინის დასამატებლად შეავსეთ ყველა ველი',
    });
};


const Index = props => {
    const dispatch = useDispatch();

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('')
    const [country, setCountry] = useState('')


    useEffect(() => {
        dispatch(getAdmins())
    }, [])

    const handleAddAdmin = () =>{
        if (login, password, role) {
            let item = {
                login,
                password,
                role,
                country
            }
            setLogin('')
            setPassword('')
            dispatch(createAdmin(item))
        }else {
            modalError('error');
        }

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="ადმინები"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col md="12">
                                             <h4>ახალი ადმინის დამატება</h4>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">ლოგინი</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={login}
                                                    onChange={(e)=> setLogin(e.target.value)}
                                                    name="email"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">პაროლი</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={password}
                                                    onChange={(e)=> setPassword(e.target.value)}
                                                    name="email"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">როლი</Label>
                                                <select
                                                    value={role}
                                                    onChange={(e)=>setRole(e.target.value)}
                                                    name="role"
                                                    className="form-control">
                                                    <option value="">--</option>
                                                    <option value="2">ოპერატორი</option>
                                                    {/*<option value="3">გაცემა</option>*/}
                                                    <option value="4">დახარისხება</option>

                                                    <option value="6">ფილიალი</option>
                                                    <option value="7">აერიპორტი</option>
                                                    <option value="5">საწყობი</option>
                                                </select>
                                            </FormGroup>
                                        </Col>

                                        {
                                            role === '5' &&<Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">country</Label>
                                                    <select
                                                        value={country}
                                                        onChange={(e)=>setCountry(e.target.value)}
                                                        name="country"
                                                        className="form-control">
                                                        <option value="">--</option>
                                                        <option value="1">Сhina</option>
                                                        <option value="2">USA</option>
                                                        <option value="3">Turkey</option>
                                                        <option value="4">Greece</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        }

                                        <Col md="3" style={{paddingTop:'27px'}}>

                                            <Button
                                                onClick={handleAddAdmin}
                                                color="primary"
                                                className="btn btn-primary waves-effect waves-light"
                                            >
                                                დამატება
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                // onRowSelect={onRowSelect}
                                // onSelectAll={onSelectAll}
                                page='index'
                                usersArray={props.admin.array}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        admin,
    } = state
    return {
        admin
    }
};
export default withRouter(connect(mapStateToProps)(Index));
