import React, {useEffect, useState, useRef} from "react"
import {Container, FormGroup, Modal} from "reactstrap"
import BarcodeReader from 'react-barcode-reader'
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Row, Table} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    getLogisticProduct,
    getLogisticProductByTrackingId,
    nullProductObject,
    updateProduct, updateSortingProduct
} from "../../store/products/actions";
import {Link, withRouter} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import errorImg from "../../assets/images/404-error.png";
import {shop} from '../../helpers/status'
import {branchAddress} from "../../helpers/branch";

const Sorting = (props) => {
    const dispatch = useDispatch();
    const {admin} = useSelector((state) => state.auth);

    const txt1 = useRef(null);
    const txt2 = useRef(null);
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [product, setProduct] = useState({})
    const [data, setData] = useState('')
    const [step, setStep] = useState(1)
    const [state, setSTate] = useState('')
    const [readyStatus, setReadyStatus] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})
    const [productValue, setProductValue] = useState(null)
    const [branches, setBranches] = useState('didube')

    useEffect(() => {
        txt1.current.focus();
    }, []);

    useEffect(() => {
        if (product._id) {
            txt2.current.focus();
        }

    }, [product._id]);

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getLogisticProduct(item))
        }
    }


    const handleUpdateInformation = () => {
        let item = {
            key,
            product
        }
        dispatch(updateProduct(item))

        setmodal_xlarge(false)
    }

    const handleChange = e => {

        const {name, value} = e.target;

        setProductValue(value)
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            userInformation: {...prevState.userInformation, [name]: value}
        }));
    };


    const handleScan = (data) => {
        dispatch(nullProductObject())
        setProductValue(null)
        let item = {
            id: data,
        }
        dispatch(getLogisticProductByTrackingId(item))
        setSTate(data)
    }

    const handleSearch = () => {
        setReadyStatus(false)
        setProductValue('')
        setStep(1)
        let item = {
            id: data,
        }
        dispatch(getLogisticProductByTrackingId(item))
    }

    const handleUpdateMiniInformation = () => {
        let item = {
            key,
            product
        }
        dispatch(updateSortingProduct(item))
        // setProduct({})
        setData('')
        setStep(2)
        txt1.current.focus();
        if (product.additionalInformation.productsReaderAll === product.additionalInformation.productsReaderTrue + 1) {
            setReadyStatus(true)
        }
    }


    const handleKeyDownUpdateInformation = (event) => {
        if (event.key === 'Enter') {

            let item = {
                key,
                product
            }
            dispatch(updateSortingProduct(item))
            // setProduct({})
            setData('')
            setStep(2)
            txt1.current.focus();
            if (product.additionalInformation.productsReaderAll === product.additionalInformation.productsReaderTrue + 1) {
                setReadyStatus(true)
            }

        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {

            setReadyStatus(false)
            dispatch(nullProductObject())
            let item = {
                id: data,
            }
            setStep(1)
            setProductValue('')
            dispatch(getLogisticProductByTrackingId(item))
        }
    }


    useEffect(() => {
        setProduct(props.products.product)
    }, [props.products.product._id])

    useEffect(() => {
        dispatch(nullProductObject())
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="დახარისხება"/>
                    <BarcodeReader
                        onScan={handleScan}
                    />
                    <Row>
                        <Col lg={8}>
                            <input
                                ref={txt1}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setData(e.target.value)}
                                value={data}
                                className="form-control" type="text"
                            />
                        </Col>
                        <Col lg={4}>
                            <Button
                                onClick={handleSearch}
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                            >
                                ძებნა
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr/>
                        </Col>
                    </Row>
                    {!isEmpty(product) && (
                        <Row>
                            <Col lg={6}>
                                <Card>
                                    <CardBody>
                                        <h4>
                                            <span>სულ: {product.additionalInformation.productsReaderAll}</span> <br/>
                                            <span>დასარიდერებელი: {product.additionalInformation.productsReaderFalse}</span>
                                            <br/>
                                            <span>დარიდერებეული: {product.additionalInformation.productsReaderTrue}</span>
                                            <br/> {readyStatus}
                                        </h4>
                                        {
                                            product.additionalInformation.productsReaderFalse === 0 || readyStatus
                                                ?
                                                <h4 style={{color: 'blue'}}>
                                                    ყველა ამანათი დარიდერდა
                                                </h4>
                                                :
                                                <h4 style={{color: 'red'}}>
                                                    ამანათების
                                                    რაოდენობა: {product.additionalInformation.productsReaderAll}
                                                </h4>
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card>
                                    <CardBody>

                                        <div>
                                            {
                                                !isEmpty(product.additionalInformation.candidate)
                                                    ? <>
                                                        {
                                                            !isEmpty(product.additionalInformation.candidate.takedPlaced)
                                                                ?
                                                                <>
                                                               {/*     <h2>
                                                                        {
                                                                            product.additionalInformation.candidate.takedPlaced.city === '_TBILISI_'
                                                                                ? 'თბილისი'
                                                                                : <>
                                                                                {
                                                                                    product.additionalInformation.candidate.takedPlaced.city  === '_KUTAISI_' ? 'ქუთაისი'
                                                                                        :product.additionalInformation.candidate.takedPlaced.city
                                                                                }

                                                                                </>
                                                                        }:
                                                                    </h2>*/}

                                                                    {
                                                                        product.branch === 'didube'
                                                                            ? <h1 style={{color: 'green'}}>   {
                                                                                branchAddress(product.branch)
                                                                            }  </h1>
                                                                            : null
                                                                    }
                                                                    {
                                                                        product.branch === 'gldani'
                                                                            ? <h1 style={{color: 'red'}}>   {
                                                                                branchAddress(product.branch)
                                                                            }  </h1>
                                                                            : null
                                                                    }
                                                                    {
                                                                        product.branch === 'isani'
                                                                            ? <h1 style={{color: 'blue'}}>   {
                                                                                branchAddress(product.branch)
                                                                            }  </h1>
                                                                            : null
                                                                    }
                                                                    {
                                                                        product.branch === 'kutaisi_1'
                                                                            ? <h1 style={{color: 'blue'}}>   {
                                                                                branchAddress(product.branch)
                                                                            }  </h1>
                                                                            : null
                                                                    }

                                                                </>
                                                                : null
                                                        }
                                                    </>
                                                    : null
                                            }


                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4 mb-4"> {product.weight} თრექინგ
                                            კოდი: {product.productOrderId}</CardTitle>
                                        <div className="table-responsive">
                                            <Table className="table-centered table-nowrap mb-0">
                                                <thead className="table-light">
                                                <tr>
                                                    <th>წონა</th>
                                                    <th>რეისი</th>
                                                    <th>AXID</th>
                                                    <th>სახელი/გვარი</th>
                                                    <th>სტატუსი</th>
                                                    <th>პროდ.</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>

                                                    <td>
                                                        <input
                                                            ref={txt2}
                                                            className="form-control"
                                                            type="text"
                                                            placeholder={product.weight}
                                                            value={productValue}
                                                            onKeyDown={handleKeyDownUpdateInformation}
                                                            onChange={handleChange}
                                                            name="weight"
                                                            disabled={step === 1 ? false : true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={product.flightId}
                                                            onChange={handleChange}
                                                            name="flightId"
                                                            disabled={step === 1 ? false : true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={product.userInformation.AXID}
                                                            name="flightId"
                                                            disabled={true}
                                                        />
                                                    </td>

                                                    <td>
                                                        {
                                                            parseInt(product.userInformation.userType) === 1 || isEmpty(product.userInformation.userType)
                                                                ?
                                                                <>
                                                                    {
                                                                        product.userInformation.lastNameKa
                                                                            ?
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={`${product.userInformation.nameKa} ${product.userInformation.lastNameKa}`}
                                                                                name="flightId"
                                                                                disabled={true}
                                                                            />
                                                                            :
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={`${product.userInformation.nameEn} ${product.userInformation.lastNameEn}`}
                                                                                name="flightId"
                                                                                disabled={true}
                                                                            />
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            parseInt(product.userInformation.userType) === 2
                                                                ?
                                                                <>
                                                                    {
                                                                        product.userInformation.indEntrepreneur
                                                                            ? <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={`${product.userInformation.nameEn} ${product.userInformation.lastNameEn} `}
                                                                                name="flightId"
                                                                                disabled={true}
                                                                            />
                                                                            : <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={`${product.userInformation.LTDNameEn} `}
                                                                                name="flightId"
                                                                                disabled={true}
                                                                            />
                                                                    }

                                                                </>
                                                                : null
                                                        }


                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={product.status}
                                                            name="flightId"
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={shop(product.productName)}
                                                            name="flightId"
                                                            disabled={true}
                                                        />
                                                    </td>

                                                    <td>
                                                        {
                                                            step === 1
                                                                ?
                                                                <>
                                                                    <Button
                                                                        onClick={handleUpdateMiniInformation}
                                                                        type="button" color="primary"
                                                                        className="btn-sm  waves-effect waves-light">
                                                                        დამახსოვრება !
                                                                    </Button>
                                                                    {' '}
                                                                    {
                                                                        admin.role === 1 &&  <Button
                                                                            onClick={() => {
                                                                                tog_large(product._id, 0)
                                                                            }}
                                                                            type="button" color="success"
                                                                            className="btn-sm   waves-effect waves-light">
                                                                            დეტალურად
                                                                        </Button>

                                                                    }

                                                                </>
                                                                : <>


                                                                    {
                                                                        admin.role === 1 && <Button
                                                                            onClick={(e) => setStep(1)}
                                                                            type="button" color="primary"
                                                                            className="btn-sm  waves-effect waves-light">
                                                                            რედაქტირება
                                                                        </Button>
                                                                    }



                                                                </>
                                                        }


                                                    </td>
                                                </tr>

                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    )}

                    {isEmpty(product) && (
                        <Row>
                            <Col md={12}>
                                <div className="text-center">
                                    <div>
                                        <Row className="row justify-content-center">
                                            <Col sm={4}>
                                                <div className="error-img">
                                                    <img src={errorImg} alt="" className="img-fluid mx-auto d-block"/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <h4 className="text-uppercase mt-4">დააბარკოდე!</h4>
                                    <p className="text-muted">ჰა რას ელოდები</p>
                                </div>
                            </Col>
                        </Row>
                    )}


                </Container>
            </div>


            <Modal size="xl" isOpen={modal_xlarge}
                   toggle={() => {
                       tog_large()
                   }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        თრექინგ კოდი: {product.productOrderId}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        !isEmpty(product._id)
                            ?
                            <>
                                <Row>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">თრექინგ კოდი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.productOrderId}
                                                onChange={handleChange}
                                                name="productOrderId"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">დასახელება</Label>
                                            <select
                                                value={product.productName}
                                                onChange={handleChange}
                                                name="productName"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="_VARIOUS_ELECTRONIC_DEVICES_">სხვადასხვა ელექტრონული
                                                    მოწყობილებები
                                                </option>
                                                <option value="_BAGS_AND_HOLDERS">ჩანთები და ჩასადებები</option>
                                                <option value="_SHOES_">ფეხსაცმელი</option>
                                                <option value="_TELEPHONE_AND_NETWORK_DEVICES">ტელეფონი და ქსელური
                                                    მოწყობილობები
                                                </option>
                                                <option value="_CLOTHING_ALL_TYPES_OF_CLOTHING_">ტანსაცმელი, ყველა ტიპის
                                                    სამოსი
                                                </option>
                                                <option value="_FOOD_SUPPLEMENTS_">საკვები დანამატები</option>
                                                <option value="_TOYS_AND_SPORTS_EQUIPMENT_">სათამაშოები და სპორტული
                                                    ინვენტარი
                                                </option>
                                                <option value="_WATCHES_">საათები</option>
                                                <option value="_PERFUMERY_AND_COSMETICS_">პარფიუმერია და კოსმეტიკა
                                                </option>
                                                <option value="_PRINTED_PRODUCTS_BOOKS_BROCHURES_">ნაბეჭდი პროდუქცია,
                                                    წიგნები, ბროშურები
                                                </option>
                                                <option value="_MUSICAL_INSTRUMENTS_AND_THEIR_PARTS_">მუსიკალური
                                                    ინსტრუმენტები და მათი ნაწილები
                                                </option>
                                                <option value="_GLASSWARE_">მინის ნაწარმი</option>
                                                <option value="_MEDICATIONS_">მედიკამენტები</option>
                                                <option value="_COMPUTER_LAPTOP_AND_THEIR_PARTS_">კომპიუტერი, ლეპტოპი და
                                                    მათი ნაწილები
                                                </option>
                                                <option value="_TOOLS_AND_HAND_TOOLS_">იარაღები და ხელის ინსტრუმენტები
                                                </option>
                                                <option value="_LIGHTING_CHANDELIERS_LAMPS_HEADLIGHTS_">განათება,
                                                    ჭაღები, ლამპები, ფარები
                                                </option>
                                                <option value="_BIJOUTERIE_">ბიჟუტერია</option>
                                                <option value="_AUTO_PARTS_">ავტო ნაწილები</option>
                                                <option value="_VARIOUS_FINISHED_PRODUCTS_">სხვადასხვა მზა ნაწარმი
                                                </option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">რაოდ</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.quantity}
                                                onChange={handleChange}
                                                name="quantity"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მყიდველის სახელი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                disabled
                                                value={product.userInformation.nameEn}
                                                name="nameEn"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მყიდველი გვარი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.lastNameEn}
                                                disabled
                                                name="lastNameEn"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">AXID</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.AXID}
                                                onChange={handleChangeInformation}
                                                name="AXID"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">ტელეფონი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.phone}
                                                onChange={handleChangeInformation}
                                                name="phone"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">პასპორტი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.passportId}
                                                onChange={handleChangeInformation}
                                                name="passportId"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მაღაზია</Label>
                                            <select
                                                value={product.shop}
                                                onChange={handleChange}
                                                name="shop"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="TAOBAO.COM">TAOBAO.COM</option>
                                                <option value="EBAY.COM">EBAY.COM</option>
                                                <option value="ALIEXPRESS.COM">ALIEXPRESS.COM</option>
                                                <option value="ALIBABA.COM">ALIBABA.COM</option>
                                                <option value="TMALL.COM">TMALL.COM</option>
                                                <option value="other">სხვა მაღაზია</option>
                                            </select>

                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">თანხა</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.price}
                                                onChange={handleChange}
                                                name="price"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">ვალუტა</Label>
                                            <select
                                                value={product.currency}
                                                onChange={handleChange}
                                                name="currency"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="CNY">CNY</option>
                                                <option value="TRY">TRY</option>
                                                <option value="GEL">GEL</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">წონა</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.weight}
                                                onChange={handleChange}
                                                name="weight"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">რეისი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.flightId}
                                                onChange={handleChange}
                                                name="flightId"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">სტატუსი </Label>
                                            <select
                                                value={product.status}
                                                onChange={handleChange}
                                                name="status"
                                                className="form-control">
                                                <option value='waiting'>waiting</option>
                                                <option value='stock'>stock</option>
                                                <option value='sent'>sent</option>
                                                <option value='arrived'>arrived</option>
                                                <option value='obtained'>obtained</option>
                                                <option value='lost'>lost</option>
                                                <option value='other'>other</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    {
                                        props.auth.admin.role === 1 &&<Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom02">გადახდა  </Label>
                                                <select
                                                    value={product.state}
                                                    onChange={handleChange}
                                                    name="state"
                                                    className="form-control">
                                                    <option value='unpaid'>არა გადახდილი</option>
                                                    <option value='paid'>გადახდილია</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label"> ფილიალი </Label>
                                            <select
                                                value={product.branch}
                                                onChange={handleChange}
                                                name="branch"
                                                className="form-control form-control-border">
                                                <option value="gldani">გლდანი</option>
                                                <option value="didube">დიდუბე</option>
                                                <option value="isani">ისანი</option>
                                                <option value="kutaisi_1">ქუთაისი</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }

                </div>
            </Modal>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    const {
        auth,
        products,
    } = state
    return {
        auth,
        products,
    }
};
export default withRouter(connect(mapStateToProps)(Sorting));

