exports.tariffsType = (value) => {
    switch (parseInt(value)) {
        case 1:
            return "ფიზიკური პირი";
            break
        case 2:
            return "იურიდიული პირი";
            break
        default:
            return 'სხვა'
    }
}

exports.countries = (value) => {
    switch (parseInt(value)) {
        case 1:
            return "ჩინეთი";
            break
        case 2:
            return "ამერიკა";
            break
        case 3:
            return "თურქეთი";
            break
        case 4:
            return "საბერძნეთი";
            break
        default:
            return 'სხვა'
    }
}

exports.countriesEn = (value) => {
    switch (parseInt(value)) {
        case 1:
            return "China";
            break
        case 2:
            return "USA";
            break
        case 3:
            return "Turkey";
            break
        case 4:
            return "საბერძნეთი";
            break
        default:
            return 'სხვა'
    }
}

exports.filial = (value) => {
    switch (value) {
        case 'qurdiani':
            return "დიდუბე";
            break
        case 'didube':
            return "დიდუბე";
            break
        case 'branch_1':
            return "დიდუბე";
            break
        case 'isani':
            return "ისანი";
            break
        case 'gldani':
            return "გლდანი";
            break
        case 'kutaisi_1':
            return "ქუთაისი";
            break
        default:
            return value
    }
}

exports.shop = (value) => {
   if (value === '_VARIOUS_ELECTRONIC_DEVICES_') {return "სხვადასხვა ელექტრონული მოწყობილებები"}
   else if (value === '_BAGS_AND_HOLDERS') {return "ჩანთები და ჩასადებები"}
   else if (value === '_SHOES_') {return "ფეხსაცმელი"}
   else if (value === '_TELEPHONE_AND_NETWORK_DEVICES') {return "ტელეფონი და ქსელური მოწყობილობები"}
   else if (value === '_CLOTHING_ALL_TYPES_OF_CLOTHING_') {return "ტანსაცმელი,ყველა ტიპის სამოსი"}
   else if (value === '_FOOD_SUPPLEMENTS_') {return "საკვები დანამატები"}
   else if (value === '_TOYS_AND_SPORTS_EQUIPMENT_') {return "სათამაშოები და სპორტული ინვენტარი"}
   else if (value === '_WATCHES_') {return "საათები"}
   else if (value === '_PERFUMERY_AND_COSMETICS_') {return "პარფიუმერია და კოსმეტიკა"}
   else if (value === '_OPTICAL_AND_PHOTOGRAPHIC_EQUIPMENT_') {return "ოპტიკური და ფოტო აპარატურა"}
   else if (value === '_PRINTED_PRODUCTS_BOOKS_BROCHURES_') {return "ნაბეჭდი პროდუქცია, წიგნები, ბროშურები"}
   else if (value === '_MUSICAL_INSTRUMENTS_AND_THEIR_PARTS_') {return "მუსიკალური ინსტრუმენტები და მათი ნაწილები"}
   else if (value === '_GLASSWARE_') {return "მინის ნაწარმი"}
   else if (value === '_MEDICATIONS_') {return "მედიკამენტები"}
   else if (value === '_COMPUTER_LAPTOP_AND_THEIR_PARTS_') {return "კომპიუტერი, ლეპტოპი და მათი ნაწილები"}
   else if (value === '_TOOLS_AND_HAND_TOOLS_') {return "იარაღები და ხელის ინსტრუმენტები"}
   else if (value === '_LIGHTING_CHANDELIERS_LAMPS_HEADLIGHTS_') {return "განათება, ჭაღები, ლამპები, ფარები"}
   else if (value === '_BIJOUTERIE_') {return "ბიჟუტერია"}
   else if (value === '_AUTO_PARTS_') {return "ავტო ნაწილები"}
   else if (value === '_VARIOUS_FINISHED_PRODUCTS_') {return "სხვადასხვა მზა ნაწარმი"}
   else {
       return value
   }
}
exports.city = (value) => {
   if (value === '_TBILISI_') {return "თბილისი"}
   else if (value === '_KUTAISI_') {return "ქუთაისი"}
   else if (value === '_BATUMI_') {return "ბათუმი"}
   else if (value === '_ABASTUMANI_') {return "აბასთუმანი"}
   else if (value === '_ABASHA_') {return "აბაშა"}
   else if (value === '_ADIGENI_') {return "ადიგენი"}
   else if (value === '_AMBROLAURI_') {return "ამბროლაური"}
   else if (value === '_ANAKLIA_') {return "ანაკლია"}
   else if (value === '_ASPINDZA_') {return "ასპინძა"}
   else if (value === '_AKHALGORI_')  {return "ახალგორი"}
   else if (value === '_AKHALKALAKI_') {return "ახალქალაქი"}
   else if (value === '_AKHALTSIKHE_') {return "ახალციხე"}
   else if (value === '_AKHMETA_') {return "ახმეტა"}
   else if (value === '_BAKURIANI_') {return "ბაკურიანი"}
   else if (value === '_BAXMARO_') {return "ბახმარო"}
   else if (value === '_BARDADI_') {return "ბაღდათი"}
   else if (value === '_BOLNISI_') {return "ბოლნისი"}
   else if (value === '_BORJOMI_') {return "ბორჯომი"}
   else if (value === '_CAGVERI_') {return "წაღვერი"}
   else if (value === '_GARDABANI_') {return "გარდაბანი"}
   else if (value === '_GONIO_') {return "გონიო"}
   else if (value === '_GORI_') {return "გორი"}
   else if (value === '_GRIGOLETI_') {return "გრიგოლეთი"}
   else if (value === '_GUDAURI_') {return "გუდაური"}
   else if (value === '_GURJAANI_') {return "გურჯაანი"}
   else if (value === '_DEDOFLISCKARO_') {return "დედოფლისწყარო"}
   else if (value === '_DMANISI_') {return "დმანისი"}
   else if (value === '_DUSETI_') {return "დუშეთი"}
   else if (value === '_VANI_') {return "ვანი"}
   else if (value === '_ZESTAFONI_') {return "ზესტაფონი"}
   else if (value === '_ZUGDIDI_') {return "ზუგდიდი"}
   else if (value === '_TETRIWYARO_') {return "თეთრიწყარო"}
   else if (value === '_TELAVI_') {return "თელავი"}
   else if (value === '_TERJOLA_') {return "თერჯოლა"}
   else if (value === '_TIANETI_') {return "თიანეთი"}
   else if (value === '_KASPI_') {return "კასპი"}
   else if (value === '_KAJRETI_') {return "კაჯრეთი"}
   else if (value === '_KVARIATI_') {return "კვარიათი"}
   else if (value === '_KOJORI_') {return "კოჯორი"}
   else if (value === '_LAGODEXI_') {return "ლაგოდეხი"}
   else if (value === '_LANCXUTI_') {return "ლანჩხუთი"}
   else if (value === '_LENTEXI_') {return "ლენტეხი"}
   else if (value === '_MARNEULI_') {return "მარნეული"}
   else if (value === '_MARTVILI_') {return "მარტვილი"}
   else if (value === '_MCXETA_') {return "მცხეთა"}
   else if (value === '_MESTIA_') {return "მესტია"}
   else if (value === '_MCVANEKONCXI_') {return "მწვანე კონცხი"}
   else if (value === '_NINOWMINDA_') {return "ნინოწმინდა"}
   else if (value === '_OZURGETI_') {return "ოზურგეთი"}
   else if (value === '_ONI_') {return "ონი"}
   else if (value === '_RUSTAVI_') {return "რუსთავი"}
   else if (value === '_SAGAREJO_') {return "საგარეჯო"}
   else if (value === '_SAGURAMO_') {return "საგურამო"}
   else if (value === '_SAIRME_') {return "საირმე"}
   else if (value === '_SAMTREDIA_') {return "სამტრედია"}
   else if (value === '_SARFI_') {return "სარფი"}
   else if (value === '_SACXERE_') {return "საჩხერე"}
   else if (value === '_SENAKI_') {return "სენაკი"}
   else if (value === '_SIRNARI_') {return "სიღნაღი"}
   else if (value === '_STEFANWMIN_') {return "სტეფანწმინდა"}
   else if (value === '_SURAMI_') {return "სურამი"}
   else if (value === '_TABAXMELA_') {return "ტაბახმელა"}
   else if (value === '_TYIBULI_') {return "ტყიბული"}
   else if (value === '_UREKI_') {return "ურეკი"}
   else if (value === '_FOTI_') {return "ფოთი"}
   else if (value === '_QARELI_') {return "ქარელი"}
   else if (value === '_QEDA_') {return "ქედა"}
   else if (value === '_QOBULETI_') {return "ქობულეთი"}
   else if (value === '_QUTAISI_') {return "ქუთაისი"}
   else if (value === '_YVARELI_') {return "ყვარელი"}
   else if (value === '_SUAXEVI_') {return "შუახევი"}
   else if (value === '_CHAQVI_') {return "ჩაქვი"}
   else if (value === '_COXATAURI_') {return "ჩოხატაური"}
   else if (value === '_CAGERI_') {return "ცაგერი"}
   else if (value === '_CXOROWYU_') {return "ჩხოროწყუ"}
   else if (value === '_WAVKISI_') {return "წავკისი"}
   else if (value === '_WALENJIXA_') {return "წალენჯიხა"}
   else if (value === '_WALKA_') {return "წალკა"}
   else if (value === '_TSEROVANI_') {return "წეროვანი"}
   else if (value === '_WNORI_') {return "წნორი"}
   else if (value === '_WYALTUBO_') {return "წყალტუბო"}
   else if (value === '_WYNETI_') {return "წყნეთი"}
   else if (value === '_WIATURA_') {return "ჭიათურა"}
   else if (value === '_XARAGAULI_') {return "ხარაგაული"}
   else if (value === '_XASHURI_') {return "ხაშური"}
   else if (value === '_XELVACAURI_') {return "ხელვაჩაური"}
   else if (value === '_XOBI_') {return "ხობი"}
   else if (value === '_XONI_') {return "ხონი"}
   else if (value === '_XULO_') {return "ხულო"}
   else {
       return value
   }
}

exports.role = (value) => {
    switch (parseInt(value)) {
        case 1:
            return "სუპერ ადმინი";
            break
        case 2:
            return "ოპერატორი";
            break
        case 3:
            return "გაცემა";
            break
        case 4:
            return "დახარისხება";
            break
        case 5:
            return "საწყობი";
            break
        case 6:
            return "ფილიალი";
            break
        case 7:
            return "აეროპორტი";
            break
        default:
            return 'სხვა'
    }
}
