import React, {useEffect, useState} from "react"
import {connect, useDispatch, useSelector} from "react-redux";
import {Button, Card, Col, FormGroup, Label, Modal, Row,Input} from "reactstrap"
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Moment from 'react-moment';

import Nulled from "../../components/Null/Nulled";
import {city} from '../../helpers/status'
import {Link, withRouter} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert";
import {deleteProduct, updateProduct} from "../../store/products/actions";
import {deleteCourier, getCourier, updateCourier} from "../../store/courier/actions";
import Courier from "../../store/courier/reducer";

const ObjectColumns = props => {
    const dispatch = useDispatch();
    const {admin} = useSelector((state) => state.auth);

    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})
    const [result, setResult] = useState({})
    const [cityS, setCityS] = useState('_TBILISI_')
    const [sq1, setsq1] = useState(false)

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        setId(id)
        setKey(key)
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getCourier(item))
        }
    }

    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteCourier(item))
        setconfirm_alert(false)
    }

    useEffect(()=>{
        setResult(props.Courier.result)
        setsq1(props.Courier.result?.thirdsUser)
        setCityS(props.Courier.result?.city)
    },[props.Courier.result._id])


    const handleChange = e => {
        const {name, value} = e.target;
        setResult(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdateInformation = () => {
        let item = {
            key,
            result : {
                _id : result._id,
                address : result.address,
                branch : result.branch,
                city : cityS,
                name : result.name,
                phone : result.phone,
                thirdsUser : sq1,
            }
        }
        dispatch(updateCourier(item))
        setmodal_xlarge(false)
    }

    const Columns = [
        {
            dataField: "AXID",
            text: "AXID",
            sort: true,
        },
        {
            dataField: "flightId",
            text: "რეისი",
            sort: true,
        },
        {
            dataField: "city",
            text: "ქალაქი",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        city(row.city)
                    }
                </>
            ),
        },
        {
            dataField: "address",
            text: "მისამართი",
            sort: true,
        },
        {
            dataField: "name",
            text: "სახელი",
            sort: true,
        },
        {
            dataField: "phone",
            text: "ტელეფონი",
            sort: true,
        },
        {
            dataField: "thirdsUser",
            text: "მესამე პირი",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.thirdsUser === true || row.thirdsUser === 'true'
                            ? <> მესამე პირი </>
                            : null
                    }
                </>
            ),
        },
        {
            dataField: "createdAt",
            text: "თარიღი",
            formatter: (cellContent, row, key) => (
                <>
                    <Moment format="YYYY-MM-DD  h:mm:ss"
                            date={row.createdAt}/>
                </>
            ),
        },
        {

            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    <span>
                     <span
                         style={{cursor: 'pointer', float: 'left'}}
                         onClick={() => {
                             tog_large(row._id, key)
                         }}
                         className="px-3 text-primary">
                         <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                     </span>

                </span>
                    <span>
                   <FeatherIcon
                       style={{cursor: 'pointer', float: 'left'}}
                       onClick={() => {
                           handleOpenAlertModal(row._id, key)
                       }}
                       icon="trash" className="icon-xs icon me-2 text-danger"/>
                </span>

                </>
            ),
        },

    ]


    return (
        <>
            {
                !isEmpty(props.array)
                    ?
                    <>
                        <Card>
                            <BootstrapTable
                                keyField="_id"
                                data={props.array}
                                columns={Columns}
                            />
                        </Card>

                    </>
                    : <Nulled/>
            }


            <Modal size="xl" isOpen={modal_xlarge}
                   toggle={() => {
                       tog_large()
                   }}
            >
                {
                    result._id
                        ?
                        <>
                            <div className="modal-header">
                                <h5
                                    className="modal-title mt-0"
                                    id="myExtraLargeModalLabel"
                                >
                                    ID კოდი: {result._id}
                                </h5>
                                <button
                                    onClick={() => {
                                        setmodal_xlarge(false)
                                    }}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">სახელი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={result.name}
                                                onChange={handleChange}
                                                name="name"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">ტელეფონი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={result.phone}
                                                onChange={handleChange}
                                                name="phone"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <div className="form-check">
                                                <Input
                                                    checked={sq1}
                                                    onChange={() => {
                                                        setsq1(!sq1)
                                                    }}
                                                    type="checkbox" className="form-check-input" id="formrow-customCheck"/>
                                                <Label className="form-check-label" htmlFor="formrow-customCheck">
                                                    ჩაიბარებს მესამე პირი
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label
                                            className='bolder-label'>ქალაქი </label>
                                        <select
                                            value={cityS}
                                            onChange={(e) => setCityS(e.target.value)}
                                            className="form-control form-control-border">
                                            <option value="_TBILISI_">თბილისი</option>
                                            <option value="_KUTAISI_">ქუთაისი</option>
                                            <option value="_BATUMI_">ბათუმი</option>
                                            <option value="_KOBULETI_">ქობულეთი</option>
                                            <option value="_ABASTUMANI_">აბასთუმანი</option>
                                            <option value="_ABASHA_">აბაშა</option>
                                            <option value="_ADIGENI_">ადიგენი</option>
                                            <option value="_AMBROLAURI_">ამბროლაური</option>
                                            <option value="_ANAKLIA_">ანაკლია</option>
                                            <option value="_ASPINDZA_">ასპინძა</option>
                                            <option value="_AKHALGORI_">ახალგორი</option>
                                            <option value="_AKHALKALAKI_">ახალქალაქი</option>
                                            <option value="_AKHALTSIKHE_">ახალციხე</option>
                                            <option value="_AKHMETA_">ახმეტა</option>
                                            <option value="_BAKURIANI_">ბაკურიანი</option>
                                            <option value="_BAXMARO_">ბახმარო</option>
                                            <option value="_BARDADI_">ბარდადი</option>
                                            <option value="_BOLNISI_">ბოლნისი</option>
                                            <option value="_BORJOMI_">ბორჯომი</option>
                                            <option value="_CAGVERI_">ცაგვერი</option>
                                            <option value="_GARDABANI_">გარდაბანი</option>
                                            <option value="_GONIO_">გონიო</option>
                                            <option value="_GORI_">გორი</option>
                                            <option value="_GRIGOLETI_">გრიგოლეთი</option>
                                            <option value="_GUDAURI_">გუდაური</option>
                                            <option value="_GURJAANI_">გურჯაანი</option>
                                            <option value="_DEDOFLISCKARO_">დედოფლისწყარო</option>
                                            <option value="_DMANISI_">დმანისი</option>
                                            <option value="_DUSETI_">დუშეთი</option>
                                            <option value="_ZESTAFONI_">ზესტაფონი</option>
                                            <option value="_ZUGDIDI_">ზუგდიდი</option>
                                            <option value="_VANI_">ვანი</option>
                                            <option value="_TETRIWYARO_">თეთრიწყარო</option>
                                            <option value="_TELAVI_">თელავი</option>
                                            <option value="_TERJOLA_">თერჯოლა</option>
                                            <option value="_TIANETI_">თიანეთი</option>
                                            <option value="_KASPI_">კასპი</option>
                                            <option value="_KAJRETI_">კაჟრეთი</option>
                                            <option value="_KVARIATI_">კვარიათი</option>
                                            <option value="_KOJORI_">კოჯორი</option>
                                            <option value="_LAGODEXI_">ლაგოდეხი</option>
                                            <option value="_LANCXUTI_">ლანჩხუთი</option>
                                            <option value="_MARNEULI_">მარნეული</option>
                                            <option value="_LENTEXI_">ლენტეხი</option>
                                            <option value="_MARTVILI_">მარტვილი</option>
                                            <option value="_MCXETA_">მცხეთა</option>
                                            <option value="_MESTIA_">მესტია</option>
                                            <option value="_MCVANEKONCXI_">მწვანე კონცხი</option>
                                            <option value="_NINOWMINDA_">ნინოწმინდა</option>
                                            <option value="_OZURGETI_">ოზურგეთი</option>
                                            <option value="_ONI_">ონი</option>
                                            <option value="_RUSTAVI_">რუსთავი</option>
                                            <option value="_SAGAREJO_">საგარეჯო</option>
                                            <option value="_SAGURAMO_">საგურამო</option>
                                            <option value="_SAIRME_">საირმე</option>
                                            <option value="_SAMTREDIA_">სამტრედია</option>
                                            <option value="_SARFI_">სარფი</option>
                                            <option value="_SACXERE_">საჩხერე</option>
                                            <option value="_SENAKI_">სენაკი</option>
                                            <option value="_SIRNARI_">სირნარი</option>
                                            <option value="_STEFANWMIN_">სტეფანწმინდა</option>
                                            <option value="_SURAMI_">სურამი</option>
                                            <option value="_TABAXMELA_">ტაბახმელა</option>
                                            <option value="_TYIBULI_">ტყიბული</option>
                                            <option value="_UREKI_">ურეკი</option>
                                            <option value="_FOTI_">ფოთი</option>
                                            <option value="_QARELI_">ქარელი</option>
                                            <option value="_QEDA_">ქედა</option>
                                            <option value="_YVARELI_">ყვარელი</option>
                                            <option value="_SUAXEVI_">სუხევი</option>
                                            <option value="_CHAQVI_">ჩაქვი</option>
                                            <option value="_COXATAURI_">ჩოხატაური</option>
                                            <option value="_CAGERI_">ცაგერი</option>
                                            <option value="_CXOROWYU_">ცხოროწუ</option>
                                            <option value="_WAVKISI_">წავკისი</option>
                                            <option value="_WALENJIXA_">წალენჯიხა</option>
                                            <option value="_WALKA_">წალკა</option>
                                            <option value="_TSEROVANI_">წეროვანი</option>
                                            <option value="_WYALTUBO_">ცხილათი / წყალტუბო</option>
                                            <option value="_WNORI_">წნორი</option>
                                            <option value="_WYNETI_">წნეთი</option>
                                            <option value="_WIATURA_">ვიატურა</option>
                                            <option value="_XARAGAULI_">ხარაგაული</option>
                                            <option value="_XASHURI_">ხაშური</option>
                                            <option value="_XELVACAURI_">ხელვაჩაური</option>
                                            <option value="_XOBI_">ხობი</option>
                                            <option value="_XONI_">ხონი</option>
                                            <option value="_XULO_">ხულო</option>

                                        </select>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">მისამართი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={result.address}
                                                onChange={handleChange}
                                                name="address"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </>
                        :null
                }


            </Modal>
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>

        </>
    )
}

const mapStateToProps = state => {
    const {
        Courier,
    } = state
    return {
        Courier,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));

