exports.branchAddress = (value) => {
    switch (value) {
        case 'didube':
            return "დიდუბე";
            break
        case 'gldani':
            return "გლდანი";
            break
        case 'isani':
            return "ისანი";
            break
        case 'kutaisi_1':
            return "ქუთაისი";
            break
        default:
            return value
    }
}
