import React, {useEffect, useState} from "react"
import {
    Row,
    Col,
    Card,
    Form,
    CardBody,
    CardTitle,
    Container, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap"
import Dropzone from "react-dropzone"

// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {Link, withRouter} from "react-router-dom"
import maintenance from "../../assets/images/maintenance.png";
import {changeStatusStep1All, stepOneResultOff} from "../../store/logistic/actions";
import {connect, useDispatch} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import SimpleBar from "simplebar-react";


const Multi = (props) => {
    const dispatch = useDispatch();
    const [selectedFiles, setselectedFiles] = useState([])
    const [result, setResult] = useState(true)
    const [fileName, setFileName] = useState('')



    const handleChangeStatusAll = () => {
        let item = {
            fileName: fileName,
        }
        dispatch(changeStatusStep1All(item))

    }

    useEffect(() => {
        dispatch(stepOneResultOff())
    }, [])

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const handleUploadFile = () => {
        const formData = new FormData();
        formData.append('file', selectedFiles[0]);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api2/upload/file`, {
            method: 'POST',
            body: formData
        }).then(response => response.json())
            .then(data => {
                if (data.result === true) {
                    setFileName(data.filename)
                    setResult(false)
                }else {
                    alert('მოხდა შეცდომა')
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Forms" breadcrumbItem="CSV ფაილის ატვირთვა"/>

                    {
                        result
                            ?
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <Dropzone
                                                    onDrop={acceptedFiles => {
                                                        handleAcceptedFiles(acceptedFiles)
                                                    }}
                                                >
                                                    {({getRootProps, getInputProps}) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted uil uil-cloud-upload"/>
                                                                </div>
                                                                <h4>Drop files here or click to upload.</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div className="dropzone-previews mt-3" id="file-previews">
                                                    {selectedFiles.map((f, i) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                alt={f.name}
                                                                                src={f.preview}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {f.name}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <strong>{f.formattedSize}</strong>
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        )
                                                    })}
                                                </div>
                                            </Form>

                                            <div className="text-center mt-4">
                                                <button
                                                    onClick={handleUploadFile}
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    ფაილის ატვირთვა
                                                </button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            :
                            <>
                                {
                                    !props.logistic.stepOneResult
                                    ?
                                        <Row>
                                            <div className="col-12 text-center">
                                                <Row className="justify-content-center">
                                                    <Col lg={4} sm={5}>
                                                        <div className="maintenance-img">
                                                            <img src={maintenance} alt=""
                                                                 className="img-fluid mx-auto d-block"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <h3 className="mt-5">ფაილი: {fileName}</h3>
                                                <h3 className="mt-5">ფაილი წარმატებით აიტვირთა</h3>
                                                <p>დააჭირეთ ბუტტონს რომ ატვირთული ფაილის გადავიდეს ბაზაში</p>
                                                <Button
                                                    onClick={handleChangeStatusAll}
                                                    color="primary"
                                                    className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1"
                                                    disabled={props.logistic.preloaderAll}
                                                >
                                                    {
                                                        props.logistic.preloaderAll === true
                                                            ? <> <ClipLoader color={'#ffffff'} loading={true} size={10}/> გადამაქვს</>
                                                            : <> მოლოდინის რეჟიმში გადატანა </>
                                                    }

                                                </Button>
                                            </div>
                                        </Row>
                                        :
                                        <>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title mb-4">სულ ჩანაწერები: {props.products.productsCount} ცალი</h5>
                                                    <h5 className="card-title mb-4">დაერორდა: {props.products.productsErrors.length} ცალი</h5>

                                                    <hr/>

                                                    {
                                                        props.products.productsCount > 0
                                                        ?
                                                            <SimpleBar className="activity-feed mb-0 ps-2" style={{ maxHeight: '436px' }}>
                                                                {
                                                                    props.products.productsErrors.map((item, key) =>{
                                                                        return(
                                                                            <li className="feed-item" key={key}>
                                                                                <div className="feed-item-list">
                                                                                    <p className="text-muted mb-1 font-size-13">AXID: <small className="d-inline-block ms-1"> {item.AXID} </small></p>
                                                                                    <p className="mt-0 mb-0">trackingId: {item.trackingId}   </p>
                                                                                    <p className="mt-0 mb-0">message: {item.message}  </p>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }

                                                            </SimpleBar>

                                                            : null
                                                    }


                                                </CardBody>
                                            </Card>

                                        </>
                                }

                            </>
                    }

                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const {
        products,
        logistic
    } = state
    return {
        products,
        logistic
    }
};
export default withRouter(connect(mapStateToProps)(Multi));

