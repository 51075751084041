import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import moment from "moment";
import {connect, useDispatch} from "react-redux";
import { Form, Input, Button, Row, Col } from "reactstrap";
import { isEmpty, map } from "lodash";
import {Link, withRouter} from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  checkAuthAdminInformation,
  checkAdminInformation,
  nullProductObject,
  geDebtSum,
  getGiveaway,
  createRates,
} from "../../store/actions";
import SuperAdmin from "./SuperAdmin";
import Operator from "./Operator";
import Gacema from "./Gacema";
import Daxarisxeba from "./Daxarisxeba";

const Header = props => {
  const dispatch = useDispatch();

  const [searchString, setSearchString] = useState('');
  const [userString, setUserString] = useState('');
  const [socialDrp, setsocialDrp] = useState(false);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  const handleSearch = () =>{
    if (searchString) {
      props.history.push(`/logistic/search/${searchString}`)
    }
  }


  const handleKeyDownSearch = (event) => {
    if (event.key === 'Enter') {
      if (searchString) {
        props.history.push(`/logistic/search/${searchString}`)
      }
    }
  }

  const handleKeyDownSearchUser = (event) => {
    if (event.key === 'Enter') {
      if (userString) {
        props.history.push(`/user/${userString}`)
      }
    }
  }

  const handleUser = () =>{
    if (userString) {
      props.history.push(`/user/${userString}`)
    }
  }

  useEffect(()=>{
    let item = {
      history : props.history
    }
    dispatch(checkAdminInformation(item))

    //  localStorage.setItem('token', callBack.token);

   let rateDate = localStorage.getItem("rateDate")

    if (!isEmpty(rateDate)) {
      localStorage.setItem('rateDate', moment().format('YYYY-MM-DD'));
      if (moment().format('YYYY-MM-DD') !== rateDate) {
        dispatch(createRates())
      }

    }else {
      localStorage.setItem('rateDate', moment().format('YYYY-MM-DD'));
    }



  },[])


  const [search, setsearch] = useState(false);
  const toggle = () => {
    setsearch (!search)
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="20" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="20" />
                </span>
              </Link>
            </div>

            <button
                type="button"
                onClick={() => {
                  tToggle();
                }}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
                id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {
              props.auth.isAuthenticated === true
                  ?
                  <>
                    {
                      ( props.auth.admin.role !== 7)
                          ? <>


                            <div  className="app-search d-none d-lg-block" >
                              <div className="position-relative">
                                <input
                                    onKeyDown={handleKeyDownSearch}
                                    onChange={(e) => setSearchString(e.target.value)}
                                    type="text"
                                    value={searchString}
                                    style={{paddingLeft:'10px', borderBottomRightRadius:'0px', borderTopRightRadius:'0px'}}
                                    className="form-control"
                                    placeholder={"ამანათი..."}
                                />
                              </div>
                            </div>
                            <div  className="app-search d-none d-lg-block" style={{marginLeft:'0px'}}>
                              <div className="position-relative">
                                <Button
                                    onClick={handleSearch}
                                    style={{height:"38px", width:'40px'}}
                                    color="primary"
                                    className="btn btn-primary waves-effect waves-light"
                                >
                                  <span className="uil-search"></span>
                                </Button>

                              </div>
                            </div>

                            <div  className="app-search d-none d-lg-block" >
                              <div className="position-relative">
                                <input
                                    onKeyDown={handleKeyDownSearchUser}
                                    onChange={(e) => setUserString(e.target.value)}
                                    value={userString}
                                    type="text"
                                    style={{paddingLeft:'10px', borderBottomRightRadius:'0px', borderTopRightRadius:'0px'}}
                                    className="form-control"
                                    placeholder={"მომხმარებელი..."}
                                />
                              </div>
                            </div>
                            <div  className="app-search d-none d-lg-block" style={{marginLeft:'0px'}}>
                              <div className="position-relative">
                                <Button
                                    onClick={handleUser}
                                    style={{height:"38px", width:'40px'}}
                                    color="success"
                                    className="btn btn-primary waves-effect waves-light"
                                >
                                  <span className="uil-search"></span>
                                </Button>

                              </div>
                            </div>

                          </>
                          : null
                    }
                  </>
                  : null
            }


          </div>

          <div className="d-flex">

            <Dropdown
                className={`d-inline-block d-lg-none ms-2`}
                isOpen={search} toggle={toggle}
                type="button"
            >
              <DropdownToggle
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  tag="button"
              > <i className="uil-search" />
              </DropdownToggle>
              <DropdownMenu className={`dropdown-menu-lg dropdown-menu-end p-0 `}>

                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">



                      <div  className="app-search d-lg-block"  style={{width:'80%', marginLeft:'0px'}}>
                        <div className="position-relative">
                          <input
                              onKeyDown={handleKeyDownSearch}
                              onChange={(e) => setSearchString(e.target.value)}
                              type="text"
                              value={searchString}
                              style={{paddingLeft:'10px', borderBottomRightRadius:'0px', borderTopRightRadius:'0px'}}
                              className="form-control"
                              placeholder={"ამანათი..."}
                          />
                        </div>
                      </div>
                      <div  className="app-search   d-lg-block" style={{marginLeft:'0px'}}>
                        <div className="position-relative">
                          <Button
                              onClick={handleSearch}
                              style={{height:"38px", width:'40px'}}
                              color="primary"
                              className="btn btn-primary waves-effect waves-light"
                          >
                            <span className="uil-search"></span>
                          </Button>

                        </div>
                      </div>




                      <div  className="app-search  d-lg-block"  style={{width:'80%'}}>
                        <div className="position-relative">
                          <input
                              onKeyDown={handleKeyDownSearchUser}
                              onChange={(e) => setUserString(e.target.value)}
                              value={userString}
                              type="text"
                              style={{paddingLeft:'10px', borderBottomRightRadius:'0px', borderTopRightRadius:'0px'}}
                              className="form-control"
                              placeholder={"მომხმარებელი..."}
                          />
                        </div>
                      </div>
                      <div  className="app-search d-lg-block" style={{marginLeft:'0px'}}>
                        <div className="position-relative">
                          <Button
                              onClick={handleUser}
                              style={{height:"38px", width:'40px'}}
                              color="success"
                              className="btn btn-primary waves-effect waves-light"
                          >
                            <span className="uil-search"></span>
                          </Button>

                        </div>
                      </div>

                    </div>
                  </div>
                </Form>

              </DropdownMenu>
            </Dropdown>

            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="uil-minus-path"></i>
              </button>
            </Dropdown>

            {/*<NotificationDropdown />*/}

            <ProfileMenu />
{/*

            <div onClick={() => {
              props.showRightSidebarAction(!props.showRightSidebar);
            }}
              className="dropdown d-inline-block">
              <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                <i className="uil-cog"></i>
              </button>
            </div>
*/}

          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    information,
    auth
  } = state;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType,  information,
    auth };
};

export default withRouter(connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(Header));


/*

const mapStateToProps = state => {
  const {
    products,
    logistic
  } = state
  return {
    products,
    logistic
  }
};
export default withRouter(connect(mapStateToProps)(Arrived));
*/
