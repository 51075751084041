import {call, put, takeEvery} from "redux-saga/effects";
import {
    PRELOADER_ON,
    PRELOADER_OFF,
    PRELOADER_CHECKED_ON,
    PRELOADER_CHECKED_OFF,
    UPDATE_CHANGE_STATUS_STEP_2_CHECKED,
    UPDATE_CHANGE_STATUS_STEP_3_CHECKED,
    UPDATE_CHANGE_STATUS_STEP_4_CHECKED,
    UPDATE_CHANGE_STATUS_STEP_5_CHECKED,
    CREATE_INVOICE_REQUEST,
    UPDATE_CHANGE_STATUS_STEP_1,
    STEP_ONE_PRELOADER_ON,
    STEP_ONE_PRELOADER_OFF,
    UPDATE_CHANGE_STATUS_ALL,
    PRELOADER_INVOICE_ON, PRELOADER_INVOICE_OFF, UPDATE_CHANGE_STATUS_STEP_6_CHECKED, PAYMENT_CHECKED_REQUEST,
} from "./actionTypes";




import {notification} from 'antd';
import {getAllInformation} from "../information/actions";
import {geDebtSum, getAllLogisticProducts, getGiveaway, getSentFlightIds} from "../products/actions";
import {UPDATE_CHANGE_STATUS_STEP_1_SUCCESS} from "../products/actionTypes";
import {getJWT} from '../../helpers/localStorage';
const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalSuccess2 = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ინვოისები წარმატებით შეიქმნა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};
const modalError2 = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'ინვოისები არა შექმნილი',
    });
};

const modalErrorNoAmount = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მომხარებელს არ აქვს საკმარისი თანხა',
    });
};
const modalErrorNoPayed = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'ამანათი არა გადახდილი',
    });
};

function* changeStatusStep1AllSaga({payload}) {
    try {
        yield put({type: PRELOADER_ON})
        yield put({type: STEP_ONE_PRELOADER_OFF})
        const callBack = yield call(changeStatusStep1AllSagaAsync, payload)
        if (callBack.result === true) {
            yield put(getAllInformation())
            yield put({type: UPDATE_CHANGE_STATUS_STEP_1_SUCCESS, callBack})
            yield put({type: STEP_ONE_PRELOADER_ON})
            yield put({type: PRELOADER_OFF})
        } else {
            yield put({type: PRELOADER_OFF})
        }
    } catch (e) {
        console.error(e);
    }
}

const changeStatusStep1AllSagaAsync = async (item) => {
    const jwt = getJWT();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/stepOne`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* changeStatusStepAllSaga({payload}) {
    try {
        yield put({type: PRELOADER_ON})
        const callBack = yield call(changeStatusStepAllSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess('success');
            yield put(getAllInformation())
            let item = {
                country: payload.country,
                status: payload.status,
                creater: payload.creater,
                skip: payload.skip,
                limit: payload.limit
            }
            yield put(getAllLogisticProducts(item))
        } else {
            if (callBack.message === 'invoice_hasnot') {
                modalError2('error');
            }else {
                modalError('error');
            }
        }
        yield put({type: PRELOADER_OFF})


    } catch (e) {
        console.error(e);
    }
}

const changeStatusStepAllSagaAsync = async (item) => {
    const jwt = getJWT();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/changeStatusAll`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* changeStatusStep2CheckedSaga({payload}) {
    try {
        yield put({type: PRELOADER_CHECKED_ON})
        const callBack = yield call(changeStatusStep2CheckedSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess('success');
        } else {
            modalError('error');
        }
        yield put({type: PRELOADER_CHECKED_OFF})
        yield put(getAllInformation())
        let item = {
            country: payload.country,
            status: payload.status,
            creater: payload.creater,
            skip: payload.skip,
            limit: payload.limit
        }
        yield put(getAllLogisticProducts(item))
    } catch (e) {
        console.error(e);
    }
}

const changeStatusStep2CheckedSagaAsync = async (item) => {
    const jwt = getJWT();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/stepTwo`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* changeStatusStep3CheckedSaga({payload}) {
    try {
        yield put({type: PRELOADER_CHECKED_ON})
        const callBack = yield call(changeStatusStep3CheckedSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess('success');
        } else {
            modalError('error');
        }
        yield put({type: PRELOADER_CHECKED_OFF})
        yield put(getAllInformation())

        const filters = {
            skip: payload.skip,
            limit: payload.limit,
            country: payload.country,
            creater: payload.creater,
            status: 'stock',
            branch:'all',
        };
        const queryParams = new URLSearchParams(filters).toString();

        yield put(getAllLogisticProducts(queryParams))
/*      */
    } catch (e) {
        console.error(e);
    }
}

const changeStatusStep3CheckedSagaAsync = async (item) => {
    const jwt = getJWT();

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/stepThree`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* changeStatusStep4ChekedSaga({payload}) {
    try {
        yield put({type: PRELOADER_CHECKED_ON})
        const callBack = yield call(changeStatusStep4ChekedSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess('success');
            yield put({type: PRELOADER_CHECKED_OFF})
            yield put(getAllInformation())
            let item = {
                id: payload.flightId,
                status : 'sent'
            }
            yield put(getSentFlightIds(item))
        } else {
            if (callBack.message === 'invoice_hasnot') {
                modalError2('error');
            }else {
                modalError('error');
            }

        }
        yield put({type: PRELOADER_CHECKED_OFF})
    } catch (e) {
        console.error(e);
    }
}

const changeStatusStep4ChekedSagaAsync = async (item) => {
    const jwt = getJWT();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/stepFour`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* changeStatusStep5ChekedSaga({payload}) {
    try {
        yield put({type: PRELOADER_CHECKED_ON})
        const callBack = yield call(changeStatusStep5ChekedSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess('success');
        } else {
            modalError('error');
        }
        yield put({type: PRELOADER_CHECKED_OFF})
        yield put(getAllInformation())
        let item = {
            status: 'arrived',
            country: payload.country,
            creater: 'all'
        }

        yield put(getAllLogisticProducts(item))
    } catch (e) {
        console.error(e);
    }
}

const changeStatusStep5ChekedSagaAsync = async (item) => {
    const jwt = getJWT();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/stepFive`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* changeStatusStep6ChekedSaga({payload}) {
    try {
        let item = {
            barCode: payload.barCode
        }
        // yield put({type: PRELOADER_CHECKED_ON})
        const callBack = yield call(changeStatusStep6ChekedSagaAsync, payload)
        if (callBack.result === true) {


            modalSuccess('success');

        } else {
            if (callBack.message === 'not_amount') {
                modalErrorNoPayed('error');
            }else {
                modalError('error');
            }
        }
        yield put(getGiveaway(item))
        yield put(getAllInformation())

    } catch (e) {
        console.error(e);
    }
}

const changeStatusStep6ChekedSagaAsync = async (item) => {
    const jwt = getJWT();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/stepSix`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* createInvoiceSaga({payload}) {
    try {
        yield put({type: PRELOADER_INVOICE_ON})
        const callBack = yield call(createInvoiceSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess2('success');
            yield put({type: PRELOADER_INVOICE_OFF})
        } else {
            modalError('error');
            yield put({type: PRELOADER_INVOICE_OFF})
        }


    } catch (e) {
        console.error(e);
    }
}

const createInvoiceSagaAsync = async (item) => {
    const jwt = getJWT();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/createInvoice`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* paymentCheckedSaga({payload}) {
    try {
        // yield put({type: PRELOADER_CHECKED_ON})
        const callBack = yield call(paymentCheckedSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess('success');
            let item = {
                barCode: payload.barCode
            }
            yield put(geDebtSum(item))
            yield put(getGiveaway(item))
        } else {
            if (callBack.message === 'not_amount') {
                modalErrorNoAmount('error');
            }
        }
    } catch (e) {
        console.error(e);
    }
}

const paymentCheckedSagaAsync = async (item) => {
    const jwt = getJWT();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/payment/pay`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* informationSaga() {
    yield takeEvery(UPDATE_CHANGE_STATUS_STEP_1, changeStatusStep1AllSaga)
    yield takeEvery(UPDATE_CHANGE_STATUS_ALL, changeStatusStepAllSaga)
    yield takeEvery(UPDATE_CHANGE_STATUS_STEP_2_CHECKED, changeStatusStep2CheckedSaga)

    yield takeEvery(UPDATE_CHANGE_STATUS_STEP_3_CHECKED, changeStatusStep3CheckedSaga)
    yield takeEvery(UPDATE_CHANGE_STATUS_STEP_4_CHECKED, changeStatusStep4ChekedSaga)
    yield takeEvery(UPDATE_CHANGE_STATUS_STEP_5_CHECKED, changeStatusStep5ChekedSaga)
    yield takeEvery(UPDATE_CHANGE_STATUS_STEP_6_CHECKED, changeStatusStep6ChekedSaga)
    yield takeEvery(CREATE_INVOICE_REQUEST, createInvoiceSaga)
    yield takeEvery(PAYMENT_CHECKED_REQUEST, paymentCheckedSaga)
}

export default informationSaga
