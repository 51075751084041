import PropTypes from "prop-types"
import React, {useCallback, useEffect, useRef} from "react"

import {withRouter} from "react-router-dom"
import {connect, useDispatch} from "react-redux";
import SuperAdmin from "./SuperAdmin";
import Gacema from "./Gacema";
import Daxarisxeba from "./Daxarisxeba";
import Operator from "./Operator";
import Filial from "./Filial";
import Aieroport from "./Aieroport";

const SidebarContent = props => {

    return (
        <>
            {
                props.auth.isAuthenticated === true
                ?
                    <>
                        {
                            props.auth.admin.role === 1
                                ? <SuperAdmin/>
                                : null
                        }
                        {
                            props.auth.admin.role === 2
                                ? <Operator/>
                                : null
                        }
                        {
                            props.auth.admin.role === 3
                                ? <Gacema/>
                                : null
                        }
                        {
                            props.auth.admin.role === 4
                                ? <Daxarisxeba/>
                                : null
                        }
                        {
                            props.auth.admin.role === 6
                                ? <Filial/>
                                : null
                        }
                        {
                            props.auth.admin.role === 7
                                ? <Aieroport/>
                                : null
                        }
                        </>
                    : null
            }


        </>
    )
}

const mapStateToProps = state => {
    const {
        information,
        auth
    } = state
    return {
        information,
        auth
    }
};

export default withRouter(connect(mapStateToProps)(SidebarContent))
