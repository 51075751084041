import React, {useEffect, useState, useRef} from "react"
import {Container, FormGroup, Modal} from "reactstrap"
import BarcodeReader from 'react-barcode-reader'
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Row, Table} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    getLogisticProduct,
    getLogisticProductByTrackingId, getLogisticProductByTrackingIdNew,
    nullProductObject,
    updateProduct, updateSortingProduct
} from "../../store/products/actions";
import {Link, withRouter} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {isEmpty} from "lodash";
import errorImg from "../../assets/images/404-error.png";
import {shop} from '../../helpers/status'
import {branchAddress} from "../../helpers/branch";

const SortingNew = (props) => {
    const dispatch = useDispatch();
    const txt1 = useRef(null);
    const txt2 = useRef(null);
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [product, setProduct] = useState({})
    const [data, setData] = useState('')
    const [step, setStep] = useState(1)
    const [state, setSTate] = useState('')
    const [readyStatus, setReadyStatus] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})
    const [productValue, setProductValue] = useState(null)
    const [branches, setBranches] = useState('didube')

    useEffect(() => {
         txt2.current.focus();
    }, []);

    useEffect(() => {
        if (product._id) {
            txt2.current.focus();
        }

    }, [product._id]);

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getLogisticProduct(item))
        }
    }


    const handleUpdateInformation = () => {
        let item = {
            key,
            product
        }
        dispatch(updateProduct(item))

        setmodal_xlarge(false)
    }

    const handleChange = e => {

        const {name, value} = e.target;

        setProductValue(value)
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            userInformation: {...prevState.userInformation, [name]: value}
        }));
    };


    const handleScan = (data) => {
        dispatch(nullProductObject())
        setProductValue(null)
        let item = {
            id: data,
        }
        dispatch(getLogisticProductByTrackingIdNew(item))
        setData('')
        setSTate(data)
    }

    const handleSearch = () => {
        setReadyStatus(false)
        setProductValue('')
        setStep(1)
        let item = {
            id: data,
        }
        setData('')
        txt2.current.focus();
        dispatch(getLogisticProductByTrackingIdNew(item))
    }


    const handleKeyDownUpdateInformation = (event) => {
        if (event.key === 'Enter') {

            let item = {
                key,
                product
            }
            dispatch(updateSortingProduct(item))
            // setProduct({})
            setData('')
            setStep(2)
           // txt1.current.focus();
            if (product.additionalInformation.productsReaderAll === product.additionalInformation.productsReaderTrue + 1) {
                setReadyStatus(true)
            }

        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {

            setReadyStatus(false)
            dispatch(nullProductObject())
            let item = {
                id: data,
            }
            setStep(1)
            setData('')
            setProductValue('')
            dispatch(getLogisticProductByTrackingIdNew(item))
        }
    }


    useEffect(() => {
        setProduct(props.products.product)
    }, [props.products.product._id])

    useEffect(() => {
        dispatch(nullProductObject())
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="დახარისხება"/>
                    <BarcodeReader
                        onScan={handleScan}
                    />
                    <Row>
                        <Col lg={8}>
                            <input
                                ref={txt2}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setData(e.target.value)}
                                value={data}
                                className="form-control" type="text"
                            />
                        </Col>
                        <Col lg={4}>
                            <Button
                                onClick={handleSearch}
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                            >
                                ძებნა
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr/>
                        </Col>
                    </Row>
                    {!isEmpty(product) && (
                        <Row>
                            <Col lg={6}>
                                <Card>
                                    <CardBody>
                                        <h4>
                                            <span>სულ: {product.additionalInformation.productsReaderAll}</span> <br/>
                                            <span>დასარიდერებელი: {product.additionalInformation.productsReaderFalse}</span>
                                            <br/>
                                            <span>დარიდერებეული: {product.additionalInformation.productsReaderTrue}</span>
                                            <br/> {readyStatus}
                                        </h4>
                                        {
                                            product.additionalInformation.productsReaderFalse === 0 || readyStatus
                                                ?
                                                <h4 style={{color: 'blue'}}>
                                                    ყველა ამანათი დარიდერდა
                                                </h4>
                                                :
                                                <h4 style={{color: 'red'}}>
                                                    ამანათების
                                                    რაოდენობა: {product.additionalInformation?.productsReaderAll}
                                                </h4>
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card>
                                    <CardBody>

                                        <div>
                                            {
                                                !isEmpty(product.additionalInformation.candidate)
                                                    ? <>
                                                        {
                                                            !isEmpty(product.additionalInformation.candidate.takedPlaced)
                                                                ?
                                                                <>
                                                            {/*        <h2>
                                                                        {
                                                                            product.additionalInformation.candidate.takedPlaced.city === '_TBILISI_'
                                                                                ? 'თბილისი'
                                                                                : <>
                                                                                    {
                                                                                        product.additionalInformation.candidate.takedPlaced.city  === '_KUTAISI_' ? 'ქუთაისი'
                                                                                            :product.additionalInformation.candidate.takedPlaced.city
                                                                                    }

                                                                                </>
                                                                        }:
                                                                    </h2>*/}

                                                                    {
                                                                        product.branch === 'didube'
                                                                            ? <h1 style={{color: 'green'}}>   {
                                                                                branchAddress(product.branch)
                                                                            }  </h1>
                                                                            : null
                                                                    }
                                                                    {
                                                                        product.branch === 'gldani'
                                                                            ? <h1 style={{color: 'red'}}>   {
                                                                                branchAddress(product.branch)
                                                                            }  </h1>
                                                                            : null
                                                                    }
                                                                    {
                                                                        product.branch === 'isani'
                                                                            ? <h1 style={{color: 'blue'}}>   {
                                                                                branchAddress(product.branch)
                                                                            }  </h1>
                                                                            : null
                                                                    }
                                                                    {
                                                                        product.branch === 'kutaisi_1'
                                                                            ? <h1 style={{color: 'blue'}}>   {
                                                                                branchAddress(product.branch)
                                                                            }  </h1>
                                                                            : null
                                                                    }

                                                                </>
                                                                : null
                                                        }
                                                    </>
                                                    : null
                                            }


                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4 mb-4"> {product.weight} თრექინგ
                                            კოდი: {product.productOrderId}</CardTitle>
                                        <div className="table-responsive">
                                            <Table className="table-centered table-nowrap mb-0">
                                                <thead className="table-light">
                                                <tr>
                                                    <th>წონა</th>
                                                    <th>რეისი</th>
                                                    <th>AXID</th>
                                                    <th>სახელი/გვარი</th>
                                                    <th>სტატუსი</th>
                                                    <th>პროდ.</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <input
                                                            // ref={txt2}
                                                            className="form-control"
                                                            type="text"
                                                            placeholder={product.weight}
                                                            value={productValue}
                                                            onKeyDown={handleKeyDownUpdateInformation}
                                                            onChange={handleChange}
                                                            name="weight"
                                                             disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={product.flightId}
                                                            onChange={handleChange}
                                                            name="flightId"
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={product.userInformation.AXID}
                                                            name="flightId"
                                                            disabled={true}
                                                        />
                                                    </td>

                                                    <td>
                                                        {
                                                            parseInt(product.userInformation.userType) === 1 || isEmpty(product.userInformation.userType)
                                                                ?
                                                                <>
                                                                    {
                                                                        product.userInformation.lastNameKa
                                                                            ?
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={`${product.userInformation.nameKa} ${product.userInformation.lastNameKa}`}
                                                                                name="flightId"
                                                                                disabled={true}
                                                                            />
                                                                            :
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={`${product.userInformation.nameEn} ${product.userInformation.lastNameEn}`}
                                                                                name="flightId"
                                                                                disabled={true}
                                                                            />
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                        {
                                                            parseInt(product.userInformation.userType) === 2
                                                                ?
                                                                <>
                                                                    {
                                                                        product.userInformation.indEntrepreneur
                                                                            ? <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={`${product.userInformation.nameEn} ${product.userInformation.lastNameEn} `}
                                                                                name="flightId"
                                                                                disabled={true}
                                                                            />
                                                                            : <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={`${product.userInformation.LTDNameEn} `}
                                                                                name="flightId"
                                                                                disabled={true}
                                                                            />
                                                                    }

                                                                </>
                                                                : null
                                                        }


                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={product.status}
                                                            name="flightId"
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={shop(product.productName)}
                                                            name="flightId"
                                                            disabled={true}
                                                        />
                                                    </td>

                                                </tr>

                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    )}

                    {isEmpty(product) && (
                        <Row>
                            <Col md={12}>
                                <div className="text-center">
                                    <div>
                                        <Row className="row justify-content-center">
                                            <Col sm={4}>
                                                <div className="error-img">
                                                    <img src={errorImg} alt="" className="img-fluid mx-auto d-block"/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <h4 className="text-uppercase mt-4">დააბარკოდე!</h4>
                                    <p className="text-muted">ჰა რას ელოდები</p>
                                </div>
                            </Col>
                        </Row>
                    )}


                </Container>
            </div>

        </React.Fragment>
    )
}
const mapStateToProps = state => {
    const {
        products,
    } = state
    return {
        products,
    }
};
export default withRouter(connect(mapStateToProps)(SortingNew));

