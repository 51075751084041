import {call, put, takeEvery} from "redux-saga/effects"
import {
    CHECK_ADMIN_INFORMATION_REQUEST,
    CHECK_ADMIN_REQUEST,
    IS_AUTHENTICAL_STATUS,
    LOGIN_REQUEST,
    LOGOUT_ADMIN_REQUEST
} from "./actionTypes"
import {notification} from 'antd';
import {checkAuthAdminInformation, checkAuthAdminInformationSuccess} from "./actions";

const modalSuccess = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'პაროლი ან ლოგინი არასწორია',
    });
};

function* loginAdminSaga({payload}) {
    try {
        const callBack = yield call(loginAdminSagaAsync, payload)
        if (callBack.result) {
            localStorage.setItem('token', callBack.token);
            yield put(checkAuthAdminInformation(payload));
        } else {
            modalSuccess('warning');
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
        }
    } catch (err) {
        console.error(err);
    }
}


const loginAdminSagaAsync = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/authorization/singin`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* checkAuthAdminInformationSaga({payload}) {
    try {
        if (localStorage.getItem("token")) {
            const callBack = yield call(checkAuthAdminInformationSagaAsync)
            if (callBack) {
                localStorage.setItem("authUser", JSON.stringify(callBack.candidate))
                yield put(checkAuthAdminInformationSuccess(callBack))
                if (callBack.candidate.role === 1) {
                    payload.history.push("/dashboard")
                }
                else if (callBack.candidate.role === 2) {
                    payload.history.push("/dashboard")
                }
                else if (callBack.candidate.role === 6) {
                    payload.history.push("/dashboard")
                }
                if (callBack.candidate.role === 3) {
                    payload.history.push("/logistic/giveaway")
                }
                else if (callBack.candidate.role === 4) {
                    payload.history.push("/logistic/sorting")
                }
                else if (callBack.candidate.role === 5) {
                    payload.history.push("/warehouse")
                }
                else if (callBack.candidate.role === 7) {
                    payload.history.push("/dashboard")
                }

            } else {
                let item = {
                    status: false
                }
                yield put({type: IS_AUTHENTICAL_STATUS, item})
                localStorage.removeItem("token")
                localStorage.removeItem("authUser")
            }
        }else {
            let item = {
                status: false
            }
            yield put({type: IS_AUTHENTICAL_STATUS, item})
        }
    } catch (err) {
        console.error(err);
    }
}


const checkAuthAdminInformationSagaAsync = async () => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/authorization/getAdminInformation`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* checkAdminInformationSaga({payload}) {
    try {
        if (localStorage.getItem("token")) {
            const callBack = yield call(checkAuthAdminInformationSagaAsync)
            if (callBack) {
                localStorage.setItem("authUser", JSON.stringify(callBack.candidate))
                yield put(checkAuthAdminInformationSuccess(callBack))
            } else {
                let item = {
                    status: false
                }
                yield put({type: IS_AUTHENTICAL_STATUS, item})
                localStorage.removeItem("token")
                localStorage.removeItem("authUser")
            }
        }else {
            let item = {
                status: false
            }
            yield put({type: IS_AUTHENTICAL_STATUS, item})
        }
    } catch (err) {
        console.error(err);
    }
}


function* authSaga() {
    yield takeEvery(LOGIN_REQUEST, loginAdminSaga)
    yield takeEvery(CHECK_ADMIN_REQUEST, checkAuthAdminInformationSaga)
    yield takeEvery(CHECK_ADMIN_INFORMATION_REQUEST, checkAdminInformationSaga)
}

export default authSaga
